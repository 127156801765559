import DownloadHelper from '@/helpers/DownloadHelper'
import { showToast } from '@/helpers/toast'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiCheckers from 'ApiRest/Api/Ctp/Checkers'
import ApiFamilyCertifications from 'ApiRest/Api/FamilyEducation/Certifications'
import ApiFamilyEducationCertificationGrades from 'ApiRest/Api/FamilyEducation/Grades'
import ApiFamilyStatementManualAttempt from 'ApiRest/Api/FamilyEducation/ManualAttempt'
import ApiFamilyEducationOptionTest from 'ApiRest/Api/FamilyEducation/Option/Test'
import ApiTheFamilyCertification from 'ApiRest/Api/FamilyEducation/TheCertification'
import ApiFamilyStatement from 'ApiRest/Api/FamilyEducation/TheStatement'
import ApiDownloadCert from 'ApiRest/Api/FamilyStatement/DownloadCert'
import { ApiFamilyStatementDownloadExcel } from 'ApiRest/Api/FamilyStatement/DownloadExcel'
import ApiOption from 'ApiRest/Api/Option'
import ApiExaminationLevel from 'ApiRest/Api/School/ExaminationLevels'
import ApiTestMarkSettings from 'ApiRest/Api/Test/MarkSetting/List'
import Constants from 'Constants'
import { pickBy } from 'lodash'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchCertifications({ commit }, params) {
    const response = await ApiFamilyCertifications.get(params)

    commit('setCertifications', response.data)

    return response
  },

  async fetchTheCertification({ commit }, familyCertificationId) {
    const { data } = await ApiTheFamilyCertification.get(familyCertificationId)

    commit('setTheCertification', data)
  },

  async createTheCertification({ commit }, payload) {
    const { data } = await ApiTheFamilyCertification.post(payload)

    commit('setTheCertification', data)
  },

  async updateTheCertification({ commit }, { familyCertificationId, payload }) {
    const { data } = await ApiTheFamilyCertification.patch(
      familyCertificationId,
      payload,
    )

    commit('setTheCertification', data)
  },

  async deleteTheCertification(context, familyCertificationId) {
    await ApiTheFamilyCertification.delete(familyCertificationId)
  },

  async fetchFamilyStatement({ state, commit }, familyCertificationId) {
    const params = pickBy({
      showOnlyCompleted: state.statementShowOnlyCompleted ? 1 : 0,
      schedule: {
        id: state.statementSchedule?.id,
      },
      stream: {
        id: state.statementStream?.id,
      },
      fio: state.statementSearchLastName,
      sort: state.statementSortField,
    })

    const { data } = await ApiFamilyStatement.get(familyCertificationId, params)

    commit('setFamilyStatement', data)
  },

  async addFamilyStatementMarkManually(context, { parameters, data }) {
    await ApiFamilyStatementManualAttempt.put(parameters, data)
  },

  async fetchFamilyCertificationSchools({ commit }) {
    const params = {
      educationType: {
        id: Constants.educationType.FAMILY,
      },
    }

    const { data } = await ApiOption.getSchools(params)

    commit('setFamilyCertificationSchools', data)
  },

  async fetchFamilyCertificationGrades({ commit }, schoolId) {
    const { data } = await ApiFamilyEducationCertificationGrades.get(schoolId)

    commit('setFamilyCertificationGrades', data)
  },

  async fetchFamilyCertificationStatuses({ commit }) {
    const { data } = await ApiOption.getFamilyEducationCertStatuses()

    commit('setFamilyCertificationStatuses', data)
  },

  async fetchGrades({ commit }) {
    const { data } = await ApiOption.getGrades()

    commit('setGrades', data)
  },

  async fetchSubjects({ commit }) {
    const { data } = await ApiOption.getSubjects()

    commit('setSubjects', data)
  },

  async fetchStatementScheduleList({ state, commit }) {
    const params = {
      school: {
        id: state.certification.school.id,
      },
    }

    const { data } = await ApiOption.getFamilyEducationCertSchedule(params)

    commit('setStatementScheduleList', data)

    if (!state.statementSchedule) {
      commit('setStatementSchedule', state.statementScheduleList[0])
    }
  },

  async fetchStatementStreamList({ state, commit }) {
    const params = {
      school: {
        id: state.certification.school.id,
      },
      grade: {
        id: state.certification.grade.id,
      },
      educationType: {
        id: Constants.educationType.FAMILY,
      },
    }

    const { data } = await ApiOption.getStreams(params)

    commit('setStatementStreamList', data)

    if (!state.statementStream) {
      commit('setStatementStream', state.statementStreamList[0])
    }
  },

  async fetchTests({ commit }, { gradeId, subjectId }) {
    const params = {
      grade: {
        id: gradeId,
      },
      subject: {
        id: subjectId,
      },
    }

    const { data } = await ApiFamilyEducationOptionTest.get(params)

    commit('setTests', data)
  },

  async fetchTestCheckers({ commit }, schoolId) {
    const { data } = await ApiCheckers.get(schoolId)

    commit('setTestCheckers', data)
  },

  async fetchTestWhenShowAnswerTypes({ commit }) {
    const { data } = await ApiOption.getCtpTestWhenShowAnswerTypes()

    commit('setTestWhenShowAnswerTypes', data)
  },

  async fetchTestShowAnswerTypes({ commit }) {
    const { data } = await ApiOption.getCtpTestShowAnswerTypes()

    commit('setTestShowAnswerTypes', data)
  },

  async fetchTestExaminationLevels({ commit }, schoolId) {
    const { data } = await ApiExaminationLevel.get(schoolId)

    commit('setTestExaminationLevels', data)
  },

  async fetchTestAttemptsMarkTypes({ commit }) {
    const { data } = await ApiOption.getCtpTestAttemptsMarkType()

    commit('setTestAttemptsMarkTypes', data)
  },

  async fetchTestMarkSettings({ commit }, { testId, payload }) {
    const { data } = await ApiTestMarkSettings.get(testId, payload)

    commit('setTestMarkSettings', data)
  },

  async downloadCertificate(context, { statementId, params }) {
    try {
      const response = await ApiDownloadCert.get(statementId, params)

      DownloadHelper.downloadBlob(response)
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        showToast('Нет данных для формирования справки', 'error')
      } else {
        showToast(error?.response?.message, 'error')
      }
    }
  },

  async downloadExcel(context, params) {
    const response = await ApiFamilyStatementDownloadExcel.get(params)

    DownloadHelper.downloadBlob(response)
  },
}
