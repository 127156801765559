import ApiBase from 'ApiRest/ApiBase'

export const ApiFamilyStatementDownloadExcel = new (class extends ApiBase {
  /**
   * @param {Object} params
   * @returns {Promise}
   */
  get(params = {}) {
    const url = `/family-statement/download?${this.getUrlParams(params)}`

    const config = {
      responseType: 'blob',
    }

    return this._GET(url, config)
  }
})()
